import { isAuthenticated } from 'flatfox_common/config';
import { formatPhoneNumberToE164 } from 'flatfox_common/format';
import { sha256 } from 'flatfox_common/hash';

const createSubscriptionElementID = 'id_create_subscription';
const createSubscriptionLocalStorageKey = 'createSubscription';

function setupLogging(form) {
  if (!form) {
    return;
  }

  const fieldSelectors = [
    'input[name=name]',
    'input[name=email]',
    'textarea[name=text]',
    'button[type=submit]',
  ];

  let started = false;

  function handleStarted() {
    if (!started) {
      logEventFacebook('ApplyForFlat', 'Started');
      started = true;
    }
  }

  // Log the 'Started' event when the user starts to fill out the
  // form
  for (let i = 0; i < fieldSelectors.length; i += 1) {
    const field = form.querySelector(fieldSelectors[i]);
    if (field) {
      field.addEventListener('click', handleStarted);
    }
  }
}

function setup() {
  const subscription_element = document.getElementById(
    createSubscriptionElementID
  ) as HTMLInputElement;

  // This element (checkbox) might not be there if user has already sent contact request
  if (subscription_element) {
    try {
      // Use a boolean value from localStorage for creating subscriptions of similar
      // listings - it can be 'true', 'false' or null.
      // For several reasons, browsers may not allow access to localStorage thus this
      // would throw SecurityError (for example, if "Block third-party cookies and site
      // data" checkbox is set in Content Settings). Some will also mark this operation
      // as insecure or just have a localStorage as null and throw TypeError; we will
      // fallback to true in those cases as those are rare and we still want to use
      // localStorage here
      const createSubscriptionValue = localStorage.getItem(
        createSubscriptionLocalStorageKey
      );
      const value = createSubscriptionValue !== null ? createSubscriptionValue : 'true';
      subscription_element.checked = JSON.parse(value);
    } catch (e) {
      subscription_element.checked = true;
    }
  }

  const contactForms = document.querySelectorAll('form[name=flat-contact-form]');
  for (let i = 0; i < contactForms.length; i += 1) {
    setupLogging(contactForms[i]);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function submitContactRequest(event: any) {
  // stop submitting form because we use async functions
  event.preventDefault();
  try {
    let encryptedEmail;
    let encryptedPhoneNumber;

    const subscription_element = document.getElementById(
      createSubscriptionElementID
    ) as HTMLInputElement;

    if (subscription_element) {
      // Add to localStorage the value of a checkbox - can be true of false
      const { checked } = subscription_element;
      try {
        localStorage.setItem(createSubscriptionLocalStorageKey, String(checked));
      } catch (e) {
        // Ignore cases when it is forbidden to access localStorage - we will default to
        // true always then
      }
    }

    if (!isAuthenticated()) {
      const emailElement = document.getElementById('id_email') as HTMLInputElement;
      const phoneElement = document.getElementById('id_phone_number') as HTMLInputElement;

      if (emailElement) {
        const email = emailElement.value;
        encryptedEmail = await sha256(email);
      }
      if (phoneElement) {
        const phone_number = phoneElement.value;
        const formattedPhoneNumber = formatPhoneNumberToE164(phone_number);
        encryptedPhoneNumber = await sha256(formattedPhoneNumber);
      }
    }

    dataLayerPush({
      event: 'contact_request',
      conversion_name: 'listing_information',
      section: 'contact_advertiser',
      tag_type: 'standard',
      user_email: encryptedEmail,
      user_phone: encryptedPhoneNumber,
    });
  } catch (ignore) {
    // the full dataLayer is not that important, important is that the form is submitted
    try {
      dataLayerPush({
        event: 'contact_request',
        conversion_name: 'listing_information',
        section: 'contact_advertiser',
        tag_type: 'standard',
      });
    } catch (ignore2) {
      // ignore
    }
  }
  event.target.submit();
}

window.flatfox.flat = { setup, submitContactRequest };
